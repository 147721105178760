<template>
  <div style="margin-bottom: 1rem">
    <div class="field" v-if="[0].includes(stage)">
      <div v-if="!hideSMSOption" class="control"  v-on:click="isSelected = !isSelected; changeWasUserGenerated = true">
        <input
         ref="sms_method"
          type="checkbox"
          name="SMSMessageOption"
          :value="isSelected"
        />
        <label
          for="SMSMessageOption"
          class="is-size-7"
          style="padding: 0px 7px;"
        >SMS Message</label>
      </div>
    </div>
    <section v-if="isSelected" class="twofa-option-container">
      <div class="field" v-if="showPhoneNumberEntryField">
        <div class="control">
          <label
            v-if="error"
            for="SMSMessageOption"
            class=""
            style="width: 100%; display: block; line-height: .75rem; font-size: .75rem; padding: .15rem; padding-bottom: .3rem!important; padding-top: 0rem!important; color: #e69138;"
          >
            {{error}}
          </label>
          <label
            v-else-if="!error && success"
            for="SMSMessageOption"
            class=""
            style="width: 100%; display: block; line-height: .75rem; font-size: .75rem; padding: .15rem; padding-bottom: .3rem!important; color: #7fb942!important;"
          >
            Phone number is verified with the provider.
          </label>
          <label
            v-else-if="!error"
            for="SMSMessageOption"
            class=""
            style="width: 100%; display: block; line-height: .75rem; font-size: .75rem; padding: .15rem; padding-bottom: .3rem!important;"
          >
            Please enter your cell phone number:
          </label>
          <div style="display: flex;">
            <SearchableSelect
              @onchange="selectCountryCode"
              :identifier="'CountryCodeSelect'"
              :options="CountryCodeOptions"
              :default="'+1'"
              :disabled="!showPhoneNumberEntryField"
              :style="{maxWidth: '85px', marginRight: '.15rem'}"
            />
            <input
              class="input"
              placeholder="5552221111"
              type="text"
              name="SMSPhoneNumberField"
              v-model="phoneNumber"
              :disabled="!showPhoneNumberEntryField"
              @keyup.enter="validate"
              @click="changeWasUserGenerated = true"
            />
          </div>
          <label
              for="SMSMessageOption"
              class=""
              style="font-size: .75rem; padding-top: .15rem; display: block; color: grey;"
            >
              The phone number provided must support text messaging.
            </label>
        </div>
        <label
            v-if="!showPhoneNumberEntryField && false"
            for="SMSMessageOption"
            class="has-text-grey"
            style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
          >
            Need to change the number? <a href="javascript:void(0)" @click="back">Edit Number</a>
          </label>
      </div>
    </section>
    <section v-if="showCodeValidationField" style="margin-top: .5rem;" class="twofa-option-container">
      <div class="field">
        <div class="control">
          <label
            for="SMSMessageOption"
            class=""
            style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
          >
            Please enter the code that was sent to: ({{phoneNumber.substr(0,3)}}) {{phoneNumber.substr(3,3)}}-{{phoneNumber.substr(6,10)}}
          </label>
          <label
            v-if="error"
            for="SMSMessageOption"
            class=""
            style="width: 100%; display: block; line-height: .75rem; font-size: .75rem; padding: .15rem; padding-bottom: .3rem!important; padding-top: 0rem!important; color: #e69138;"
          >
            {{error}}
          </label>
          <div style="display: flex;">
            <input
              ref="sms"
              class="input"
              placeholder="123456"
              type="text"
              name="SMSPhoneNumberField"
              v-model="validationCode"
              @keyup.enter="validateCode"
              @click="changeWasUserGenerated = true"
            />
          </div>
          <label
            for="SMSMessageOption"
            class="has-text-grey"
            style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
          >
            Haven't received a code? <a :class="{ 'has-text-grey': codeWasResent }" :disabled="codeWasResent" href="javascript:void(0)" @click="resendCode" v-if="!showPhoneNumberEntryField">{{ codeWasResent ? 'Code Sent' : 'Resend Code' }}</a>
          </label>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import CountryCodes from './../../../helpers/CountryCodes.js'
import SearchableSelect from '../SearchableSelect.vue'

export default {
  name: 'SMSOption',
  components: {
    SearchableSelect
  },
  props: {
    hideSMSOption: {
      type: Boolean,
      required: false
    },
    selectedStatus: {
      type: Boolean,
      required: false
    },
    next: {
      type: Boolean,
      required: false
    },
    phoneNumberString: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    },
    stage: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      error: '',
      success: false,
      phoneNumber: '',
      isLoading: false,
      isSelected: false,
      changeWasUserGenerated: false,
      showCodeValidationField: false,
      showPhoneNumberEntryField: true,
      codeWasResent: false,
      validationCode: '',
      countryCode: '+1'
    }
  },
  mounted () {
    this.changeWasUserGenerated = false
    this.isSelected = this.selectedStatus
    this.checkbox(this.$refs.sms_method, this.isSelected)

    // Remove the defaulting to users phone number
    // this.phoneNumber = this.phoneNumberString.replace(/\D/g, '').substr(0, 10)

    const func = () => {
      if (this.success) {
        this.$emit('unlock')
      }
    }

    setInterval(func.bind(this), 500) // add-additional-phone-validation
  },
  computed: {
    CountryCodeOptions () {
      let codes = {}

      CountryCodes.map((country) => {
        codes[`${country.dial_code}`] = `${country.code} ${country.dial_code}`
      })

      return codes
    }
  },
  watch: {
    errorMessage (val) {
      this.error = val
    },
    isSelected () {
      if (this.changeWasUserGenerated) {
        this.$emit('isSelected', this.isSelected)

        if (this.phoneNumber) {
          this.checkPhoneNumber(this.phoneNumber)
        }
      }
    },
    selectedStatus (isSelected) {
      this.changeWasUserGenerated = false
      this.isSelected = isSelected
      this.checkbox(this.$refs.sms_method, isSelected)
      this.showCodeValidationField = false
      this.showPhoneNumberEntryField = true
    },
    phoneNumber (phoneNumber) {
      this.checkPhoneNumber(phoneNumber)
    },
    validationCode () {
      this.validationCode = this.validationCode.replace(/\D/g, '').substr(0, 6)

      if (this.validationCode.length < 6) {
        this.$emit('lock')
      } else if (this.validationCode.length === 6) {
        this.$emit('unlock')
      } else {
        this.$emit('lock')
      }
    },
    next (value) {
      this.showCodeValidationField = value
      this.showPhoneNumberEntryField = !value
    }
  },
  methods: {
    selectCountryCode (select) {
      this.countryCode = select.value
    },
    async checkPhoneNumber (phoneNumber) {
      let that = this
      if (this.phoneNumber.length < 10 || this.phoneNumber.length > 10) {
        // Make sure we trim the phone number.
        this.phoneNumber = phoneNumber.replace(/\D/g, '').substr(0, 10)

        this.error = 'Please use a 10 digit phone number.'
        this.lock()
      } else if (this.phoneNumber.length === 10) {
        this.api().sms.verifyPhoneNumber(this.phoneNumber, (_result, _error) => {
          if (_error || (_error === null && _result === false)) {
            that.error = 'Unable to verify phone number. Please check the format and ensure it can receive texts.'
            that.success = false
            that.lock()
          } else if (_result) {
            that.error = ''
            that.success = true
            that.$emit('unlock')
          }
        })
      }
    },
    validate () {
      this.showCodeValidationField = true
      this.showPhoneNumberEntryField = false

      this.$emit('next', {})
    },
    validateCode () {
      this.$emit('next')
    },
    back () {
      this.showPhoneNumberEntryField = true
      this.showCodeValidationField = false
      this.$emit('back', {})
    },
    lock () {
      this.$emit('lock')
    },
    resendCode () {
      this.$emit('resend')
      this.codeWasResent = true

      let func = () => {
        this.codeWasResent = false
      }

      setTimeout(func, 3000)
    }
  }
}
</script>
