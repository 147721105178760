<template>
  <div class="custom-select" @blur="open = false" v-clickoutside="close">
    <div class="selected" :class="{ open: open }" @click="open = !open" v-html="selected" :disabled="disabled"></div>
    <div class="items" :class="{ selectHide: !open }">
      <div v-for="(option, i) of options" :key="i" @click="selectOption(i)" v-html="option"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: [Object, Array], // can be either.
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
    default: {
      type: [Number, String],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      open: false,
      selected: this.getSelected(),
      key: null
    }
  },
  mounted () {
    let that = this
    let current = null
    Object.keys(this.options).forEach(function (key) {
      if (that.options[key] === that.selected) {
        current = key
      }
    })

    this.$emit('onchange', {
      identifier: this.identifier,
      value: current
    })
  },
  methods: {
    getSelected () {
      let selected = null

      if (this.selectedOption) {
        selected = this.selectedOption
      } else if (this.defaultAlt) {
        selected = this.options[this.defaultAlt]
      } else if (!this.defaultAlt && this.default) {
        selected = this.options[this.default]
      } else if (Object.keys(this.options).length > 0) {
        selected = this.options[Object.keys(this.options)[0]]
      }

      return selected
    },
    setSelected (value) {
      this.key = value
      this.open = false
      this.$set(this, 'selected', this.options[value])
      this.$emit('onchange', {
        identifier: this.identifier,
        value: this.key
      })
    },
    setOpen (isOpen = null) {
      if (!this.disabled && isOpen === null) {
        this.open = !this.open
      } else if (isOpen !== null) {
        this.open = isOpen
      }
    },
    close () {
      this.open = false
    },
    selectOption (key) {
      this.key = key
      this.open = false
      this.$set(this, 'selected', this.options[key])
      this.$emit('onchange', {
        identifier: this.identifier,
        value: key
      })
    }
  }
}
</script>

<style scoped>

.custom-select {
  position: relative;
  width: 100%;
  text-align: left;
  outline: none;
  height: 1.35rem;
  font-size: .75rem!important;
  line-height: 35px;
}

.custom-select .selected {
  background-color: white;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border: 1px solid #ededed;
  border-color: #dbdbdb;
  border-radius: 4px;
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.custom-select .selected[disabled="disabled"] {
  background-color: lightgray;
  border: 1px solid #ededed;
  padding-left: 1em;
  cursor:not-allowed;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-radius: 6px;
}

.custom-select .selected.open {
  border: 1px solid #ededed;
  border-radius: 6px 6px 0px 0px;
}

.custom-select .selected:after {
  position: absolute;
  content: "";
  top:  17px;
  right: .75em;
  width: 20;
  height: 0;
  border: 5px solid transparent;
  border-color: #444444 transparent transparent transparent;
}

.custom-select .items {
  border-radius: 0px 0px 6px 6px;
  overflow: hidden;
  overflow-y: scroll;
  max-height: 30vh;
  position: absolute;
  background-color: white;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  left: 0;
  right: 0;
  z-index: 999;
}

.custom-select .items div {
  padding-left: 1em;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.custom-select .items div:hover {
  background-color: whitesmoke;
}

.selectHide {
  display: none;
}
</style>
