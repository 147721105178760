<template>
  <div style="margin-bottom: 1rem">
    <div class="field" v-if="[0].includes(stage)">
      <div class="control" v-on:click="toggle">
        <input
          ref="email_method"
          type="checkbox"
          name="EmailMessageOption"
          :value="isSelected"
        />
        <label
          for="EmailMessageOption"
          class="is-size-7"
          style="padding: 0px 7px;"
          >Email Message</label
        >
      </div>
    </div>
    <section v-if="isSelected" class="twofa-option-container">
      <label
            v-if="error"
            for="SMSMessageOption"
            class=""
            style="width: 100%; display: block; line-height: .75rem; font-size: .75rem; padding: .15rem; padding-bottom: .3rem!important; padding-top: 0rem!important; color: #e69138;"
          >
            {{error}}
          </label>
      <div class="field" v-if="showemailEntryField">
        <div class="control">
          <label
            for="EmailMessageOption"
            class=""
            style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
          >
            An email will be sent to the email you used to login.
          </label>
          <div style="display: flex;">
            <input
              class="input"
              placeholder="XXXX@XXXX.COM"
              type="text"
              name="EmailemailField"
              :disabled="true"
              v-model="email"
              @keyup.enter="validate"
              @click="changeWasUserGenerated = true"
            />
          </div>
        </div>
        <label
          v-if="!showemailEntryField && false"
          for="EmailMessageOption"
          class="has-text-grey"
          style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
        >
          Need to change the email?
          <a href="javascript:void(0)" @click="back">Edit Number</a>
        </label>
      </div>
    </section>
    <section
      v-if="showCodeValidationField"
      style="margin-top: .5rem;"
      class="twofa-option-container"
    >
      <div class="field">
        <div class="control">
          <label
            v-if="!error"
            for="EmailMessageOption"
            class=""
            style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
          >
            Please enter the code that was sent to: {{email}}
          </label>
          <label
            v-if="error"
            for="SMSMessageOption"
            class=""
            style="width: 100%; display: block; line-height: .75rem; font-size: .75rem; padding: .15rem; padding-bottom: .3rem!important; padding-top: 0rem!important; color: #e69138;"
          >
            {{error}}
          </label>
          <div style="display: flex;">
            <input
              class="input"
              placeholder="123456"
              type="text"
              name="EmailemailField"
              v-model="validationCode"
              @keyup.enter="validateCode"
              @click="changeWasUserGenerated = true"
            />
          </div>
          <label
            for="EmailMessageOption"
            class="has-text-grey"
            style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
          >
            Haven't received a code?
            <a
              :class="{ 'has-text-grey': codeWasResent }"
              :disabled="codeWasResent"
              href="javascript:void(0)"
              @click="resendCode"
              v-if="!showemailEntryField"
              >{{ codeWasResent ? "Code Sent" : "Resend Code" }}</a
            >
          </label>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export default {
  name: 'EmailOption',
  props: {
    selectedStatus: {
      type: Boolean,
      required: false
    },
    next: {
      type: Boolean,
      required: false
    },
    emailString: {
      type: String,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    },
    stage: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      error: '',
      isLoading: false,
      isSelected: false,
      changeWasUserGenerated: false,
      showCodeValidationField: false,
      showemailEntryField: true,
      codeWasResent: false,
      validationCode: ''
    }
  },
  mounted () {
    this.changeWasUserGenerated = false
    this.isSelected = this.selectedStatus

    const func = () => {
      if (this.isSelected) {
        this.$emit('unlock')
      }
    }

    setInterval(func.bind(this), 500)
  },
  watch: {
    errorMessage (val) {
      this.error = val
    },
    isSelected () {
      if (this.changeWasUserGenerated) {
        this.$emit('isSelected', this.isSelected)
        this.$emit('unlock')
      }
    },
    selectedStatus (isSelected) {
      this.changeWasUserGenerated = false
      this.isSelected = isSelected
      this.checkbox(this.$refs.email_method, isSelected)
      this.showCodeValidationField = false
      this.showemailEntryField = true
    },
    next (value) {
      this.showCodeValidationField = value
      this.showemailEntryField = !value
    },
    validationCode () {
      this.validationCode = this.validationCode.replace(/\D/g, '').substr(0, 6)

      if (this.validationCode.length < 6) {
        this.$emit('lock')
      } else if (this.validationCode.length === 6) {
        this.$emit('unlock')
      }
    }
  },
  computed: {
    email () {
      let emailParts = this.emailString.split('@')
      let emailName = `${emailParts[0]}`.slice(0, 1)
      let maskedEmailName = `${emailName}`
      let maskedEmailDomain = `${emailParts[1].split('.')[0]}`.slice(0, 1)

      for (let i = 0; i < emailParts[0].length - 1; i++) {
        maskedEmailName += '•'
      }

      maskedEmailName += `${emailParts[0]}`.slice(
        emailParts[0].length - 1,
        emailParts[0].length
      )

      for (let i = 1; i < emailParts[1].split('.')[0].length - 1; i++) {
        maskedEmailDomain += '•'
      }

      maskedEmailDomain +=
        `${emailParts[1].split('.')[0]}`.slice(
          emailParts[1].split('.')[0].length - 1,
          emailParts[1].split('.')[0].length
        ) +
        '.' +
        `${emailParts[1].split('.')[1]}`.slice(
          emailParts[1].split('.'[1]).length - 1,
          emailParts[1].split('.')[1].length
        )
      return `${maskedEmailName}@${maskedEmailDomain}`
    }
  },
  methods: {
    toggle () {
      // console.log('selected')
      this.isSelected = !this.isSelected
      this.changeWasUserGenerated = true

      // this.$emit('isSelected', this.isSelected)
      // this.$emit('unlock')

      if (this.isSelected) {
        this.$refs.email_method.checked = 'checked'
      } else {
        this.$refs.email_method.checked = undefined
      }
    },
    validate () {
      this.showCodeValidationField = true
      this.showemailEntryField = false

      this.$emit('next', {})
    },
    back () {
      this.showemailEntryField = true
      this.showCodeValidationField = false
      this.$emit('back', {})
    },
    validateCode () {
      this.$emit('next')
    },
    resendCode () {
      this.$emit('resend')
      this.codeWasResent = true

      let func = () => {
        this.codeWasResent = false
      }

      setTimeout(func, 3000)
    }
  }
}
</script>
