<template>
  <section class="hero is-fullheight" style="background-color: #F1F1F1;">
    <vue-headful :title="pageTitle" />
    <div class="hero-body">
      <div class="container" style="width:99vw;">
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <div id="login">
              <div v-show="isIE" style="padding: 2rem;">&nbsp;</div>
              <div>
                <figure class="has-text-centered">
                  <img
                    :src="activeSite.logoUrl"
                    style="max-width: 250px;"
                  />
                </figure>
                  <div v-if="[0,1].includes(stage)">
                    <SMSOption
                      ref="sms"
                      v-if="user && (isSMSSelected || isEmailSelected)"
                      :phoneNumberString="''"
                      :next="isSMSNext"
                      :selectedStatus="isSMSSelected"
                      :errorMessage="error"
                      :hideSMSOption="!hasEmailOption"
                      :stage="stage"
                      @resend="resend"
                      @next="next"
                      @back="back"
                      @unlock="lockNextButton = false"
                      @lock="lockSMS"
                      @isSelected="setSMS"
                    />
                    <EmailOption
                      ref="email"
                      v-if="user && hasEmailOption"
                      :emailString="user.email ? user.email : ''"
                      :next="isEmailNext"
                      :selectedStatus="isEmailSelected"
                      :errorMessage="error"
                      :stage="stage"
                      @resend="resend"
                      @next="next"
                      @back="back"
                      @unlock="lockNextButton = false"
                      @lock="lockNextButton = true"
                      @isSelected="setEmail"
                    />
                  </div>

                  <div style="text-align: center; padding-bottom: 2rem;" v-else>
                    2FA is now enabled on your account!
                  </div>

                  <div class="is-clearfix">
                    <div class="field is-pulled-right">
                      <div class="control">
                        <a
                          v-if="[1].includes(stage)"
                          class="button has-background-light"
                          style="margin-right: .5rem;"
                          :disabled="!isSMSNext && !isEmailNext"
                          v-bind:class="{ 'is-loading': isLoading }"
                          v-on:click.prevent="stage === 0 ? () => {} : back()"
                        >Back</a>
                        <a
                          :disabled="isLoading2FA || lockNextButton"
                          class="button is-accent"
                          v-bind:class="{ 'is-loading': isLoading }"
                          v-on:click.prevent="isLoading2FA || lockNextButton ? () => {} : next()"
                        >{{ isLoading2FA ? 'Sending' : stage === 2 ? 'Finish' : 'Next'}}
                        <spin-loader v-if="isLoading2FA"/>
                      </a>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="column"></div>
        </div>
        <div
          style="width: 100%; word-wrap: none; white-space: nowrap; padding-top: 3rem!important; line-height: .75rem; font-size: .8rem; color: #00467f; font-weight: 600; opacity: .75; text-align: center;"
        >
          Please contact BCC Client Services at bccclientservices@bcc-usa.com, if any issues are encountered.
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  activeSite,
  updateSession
} from '../vuex-actions'

import EmailOption from './partials/2fa/Email.vue'
import SMSOption from './partials/2fa/SMS.vue'

const phoneOnlyRoles = ['Staff', 'Manager', 'TEAM']

export default {
  components: {
    EmailOption,
    SMSOption
  },
  name: 'Main2faScreen',
  props: {
    passwordWasValidated: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      isLoading2FA: false,
      lockNextButton: false,
      stage: 0,
      isLoading: false,
      input: {
        method: 0,
        phone_number: '',
        email: ''
      },
      isEmailSelected: false,
      isSMSSelected: true,
      isSMSNext: false,
      isEmailNext: false,
      pageName: '2FA Setup',
      user: null,
      error: ''
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return this.pageName + ' - ' + this.activeSite.displayName
    },
    isIE () {
      var ua = navigator.userAgent
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    },
    IsUnsupported () {
      if (navigator.appVersion.indexOf('MSIE 10') !== -1) {
        return true
      }
      return false
    },
    hasEmailOption () {
      return phoneOnlyRoles.includes(this.clientSession.userRole) === false &&
      this.user &&
      this.user.email &&
      this.user.email !== '' &&
      !(this.user.email.includes('@sparkfish.com') || this.user.email.includes('@bcc-usa.com'))
    }
  },
  mounted () {
    let that = this
    let hasMFAEnabled = this.clientSession.mfaChannel !== '' && this.clientSession.mfaChannel !== null && this.clientSession.mfaChannel !== undefined && this.clientSession.mfaChannel !== 'Undefined'
    if (hasMFAEnabled && this.passwordWasValidated === false) {
      this.$router.back()
    }

    this.lockNextButton = true

    this.getUserDetails()
    this.$nextTick(() => {
      that.setSMS(true)
    })
  },
  methods: {
    ...mapMutations([updateSession]),
    async getUserDetails () {
      try {
        let route = `/users/${this.clientSession.userId}/mfa-options`
        this.isLoading = true
        let response = await this.axios.get(route)
        this.user = response.data
      } catch (error) {
        this.handleApiErr(error)
      } finally {
        this.isLoading = false
      }
    },
    setSMS (isSelected) {
      this.isEmailSelected = !isSelected
      this.isSMSSelected = isSelected
      this.stage = 0

      this.isLoading2FA = false

      this.resetNext()

      this.$forceUpdate()
    },
    setEmail (isSelected) {
      this.isEmailSelected = isSelected
      this.isSMSSelected = !isSelected
      this.stage = 0

      this.isLoading2FA = false

      this.resetNext()

      this.$forceUpdate()
    },
    resetNext () {
      this.isEmailNext = false
      this.isSMSNext = false
    },
    async resend () {
      this.stage--
      await this.sendCode()
    },
    async next () {
      if (this.stage === 0) {
        await this.sendCode()
      } else if (this.stage === 1) {
        await this.verifyCode()
      } else {
        this.stage++
      }

      if (this.stage === 3) {
        this.$router.push('/dashboard')
      }
    },
    async verifyCode () {
      this.isLoading2FA = true
      let code = null

      if (this.isEmailSelected && !this.isSMSSelected) {
        code = this.$refs.email.validationCode
      }

      if (!this.isEmailSelected && this.isSMSSelected) {
        code = this.$refs.sms.validationCode
      }

      let response = null

      if (code !== undefined && code !== null && code !== '') {
        response = await this.axios.post(`/mfa/verify?code=${code}`, {
          code: code
        })
      }

      if (response && response.status >= 200 && response.status < 300) {
        this.clientSession.isMfaValid = true
        this.clientSession.mfaChannel = this.isEmailSelected && !this.isSMSSelected ? 1 : 2
        this[updateSession](this.clientSession)
        this.isLoading2FA = false
        this.stage++
        this.lockNextButton = false
      } else {
        this.isLoading2FA = false
        this.lockNextButton = false

        this.error = '2FA Code was invalid'
      }
    },
    lockSMS (e) {
      this.lockNextButton = true
    },
    async sendCode () {
      this.isLoading2FA = true
      let data = {}

      if (this.isEmailSelected && !this.isSMSSelected) {
        data = {
          channel: 1
        }
      }

      if (!this.isEmailSelected && this.isSMSSelected) {
        data = {
          channel: 2,
          phoneNumber: this.$refs.sms.countryCode + this.$refs.sms.phoneNumber
        }
      }
      let queryString = ''

      if (data.channel) {
        queryString += `/mfa/setup?channel=${data.channel}`
      }

      if (data.phoneNumber) {
        queryString += `&phoneNumber=${encodeURIComponent(data.phoneNumber)}`
      }

      let response = await this.axios.post(queryString.replace('undefined', ''), data)

      if (response.status >= 200 && response.status < 300) {
        this.isEmailNext = this.isEmailSelected
        this.isSMSNext = this.isSMSSelected
        this.isLoading2FA = false
        this.stage++
        this.lockNextButton = true
      } else {
        this.isLoading2FA = false
        this.lockNextButton = false

        this.error = 'Unable to send code to email.'
      }
    },
    back () {
      this.isEmailNext = false
      this.isSMSNext = false
      this.stage--
    }
  }
}
</script>
